<template>
    <div class="about">
        <Header></Header>
        <div class="about_content">
            <div class="ac_content">
                <div class="title">贵州欣享云智能科技有限公司</div>
                <div class="title2">公司介绍</div>
                <div class="text">
                  　　<p>贵州欣享云智能科技有限公司成立于2020年9月，总部位于贵阳市观山湖区，是一家集智能设备及平台软件开发、投资、运营为一体的高科技公司。目前，我公司拥有自主知识产权的共享自助打印照相终端设备、云打印平台已研发完成并上线，并且还在研发新型设备，不断改良我们的产品，以给用户带来更好的体验。</p>
                  　　<p>未来5年，公司将携手合作商一起，计划在全国各地高校、职业院校、上规模的初高中、景区、打字复印店、机场、高铁站、汽车站、政务中心、车管所及广大农村地区陆续投放5万台共享自助打印照相终端机设备，赋予传统照相打字业务新的生命力，方便群众的同时，带领合作商共同发展。</p>
                  　　<p>同时，云打印平台将为全国90多万家传统打字复印店赋能，节省门店租金，降低人工成本，为传统打字复印引流，拓展同城新业务。经营者将会更轻松，工作更高效，利润更可观。</p>
                  　　<p>另外，我公司一些融合共享、智能等元素的优质项目，已完成项目风险评估和商业策划，即将进入到实施阶段，在不远的未来（1年内）也会逐步上市，带给合作商新的发展机遇。</p>
                  　　<p>总之，我公司将本着“诚信、创新、分享、多赢”的经营理念，与时俱进、诚信经营、勇于创新、率先分享，最终实现公司、员工、合作伙伴及社会的多方共赢。</p>
                      <p><b>热线电话：400-8807225</b></p>
                </div>
                <div class="img_wrap">
                  <img src="~@/assets/common/img3.jpg" alt="" style='width:507px;height:274px;border-radius:20px;'>
                  <img src="~@/assets/common/img4.jpg" alt="" style='width:507px;height:274px;border-radius:20px;'>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
    data(){
        return{
        }
    },
    components:{
        Header,
        Footer
    }
}
</script>

<style lang="less">
  .about{
    .about_content{
      min-height:calc(100vh - 86px - 60px);
      .ac_content{
        max-width: @max_width;
        margin: 0 auto;
        .title{
          font-size: 28px;
          font-weight: bold;
          padding: 50px 0;
        }
        .title2{
          font-size: 16px;
          font-weight: bold;
        }
        .text{
          p{
            padding-top: 10px;
            text-align: justify;
            line-height: 30px;
            text-indent: 2em;
          }
        }
        .img_wrap{
          display: flex;
          justify-content: space-between;
          padding: 20px 0;
        }
      }
    }
  }
</style>